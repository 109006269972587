import request from '../utils/request.js'

// 获取最新版本
export function appUpgrade(data) {
    return request({
        url: '/app/upgrade/get',
        method: 'GET',
        params: data
    })
}

// 协议
export function settingAgreement(data) {
    return request({
        url: '/system-setting/agreement',
        method: 'GET',
        params: data
    })
}