import request from '../utils/request.js'

// 登录验证码
export function sendSms(data) {
    return request({
        url: '/auth/sms',
        method: 'POST',
        data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/auth/login',
        method: 'POST',
        data
    })
}