<template>
    <div class="app-container support">
        <p>如果您在使用过程中有问题请留言或发邮件我。</p>
        <p>邮箱地址：<a href="mailto:colohm@163.com">colohm@163.com</a></p>
        <p>谢谢!</p>
        <p class="step"></p>
        <p>If you have any questions, please leave a message or send me an email.</p>
        <p>Email：<a href="mailto:colohm@163.com">colohm@163.com</a></p>
        <p>Thank you!</p>
    </div>
</template>

<script>
</script>

<style scoped>
.support {
    padding: 0.48rem 0.32rem 0;
}
.support p {
    line-height: 0.48rem;
    text-align: justify;
    color: #333;
}
.support .step {
    height: 0.48rem;
}
.support a {
    color: #FD256C;
}
</style>