<template>
    <div class="app-container login">
        <div class="login-title">用户注册</div>
        <div class="login-box">
            <div class="login-input">
                <img src="../assets/images/invite.png" alt="" class="img">
                <input class="login-input-val" type="tel" v-model="invite_code" readonly>
            </div>
            <div class="login-input">
                <img src="../assets/images/mobile.png" alt="" class="img">
                <input maxlength="11" class="login-input-val" type="tel" v-model="phone" placeholder="请输入手机号码">
            </div>
            <div class="login-input">
                <img src="../assets/images/lock-fill.png" alt="" class="img">
                <input maxlength="4" class="login-input-val" type="tel" v-model="sms_code" placeholder="请输入手机验证码">
                <button class="btn gray" v-if="codes.disabled">{{ codes.text }}</button>
                <button class="btn" @click="getMobileCode" v-else>{{ codes.text }}</button>
            </div>
            <div class="login-xy">
                <img src="../assets/images/checked-yes.png" alt="" class="img">
                <!-- <img src="../assets/images/checked.png" alt="" class="img"> -->
                <p class="txt">我阅读并同意</p>
                <router-link class="txt link" to="/privacy?key=register">《用户使用协议》</router-link>
                <router-link class="txt link" to="/privacy?key=privacy">《隐私政策》</router-link>
                <router-link class="txt link" to="/privacy?key=partner_agreement">《合伙人/推广员须知》</router-link>
            </div>
            <div class="login-btn">
                <button class="btn" @click="toLogin">注册</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { login, sendSms } from '../api/auth.js'
import { isMobile } from '../utils/util.js'

let phone = ref('')
let sms_code = ref('')
let invite_code = ref('')
let agreement = ref(true)
const codes = reactive({
    text: '获取验证码',
    disabled: false
})

const router = useRouter()

onMounted(() => {
    invite_code.value = router.currentRoute.value.query.invitecode
})

const getMobileCode = function () {
    if (!isMobile(phone.value)) {
        alert('请输入正确的手机号')
        return false
    }
    codes.disabled = true
    let times = 59
    codes.text = `重新获取${times}S`
    const timer = setInterval(() => {
        if (times > 0) {
            times--
            codes.text = `重新获取${times}S`
        } else {
            clearInterval(timer)
            codes.disabled = false
            codes.text = '获取验证码'
        }
    }, 1000)
    sendCodes()
}

const sendCodes = function () {
    const data = {
        phone: phone.value
    }
    sendSms(data).then(() => {
        alert('发送成功')
    })
}

const toLogin = function () {
    const data = {
        type: 0,
        agreement: agreement.value,
        phone: phone.value,
        sms_code: sms_code.value,
        invite_code: invite_code.value
    }
    login(data).then(() => {
        router.push('/')
    })
}
</script>

<style scoped>
.login {
    padding: 2.4rem 0.48rem 0;
}
.login-title {
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.4rem;
    margin-bottom: 0.8rem;
}
.login-input {
    border-radius: 0.4rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.24rem;
}
.login-input .img {
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    left: 0.24rem;
    top: 0;
    bottom: 0;
    margin: auto;
}
.login-input .btn {
    color: #FD256C;
    height: 0.8rem;
    position: absolute;
    top: 0;
    right: 0.32rem;
    background-color: transparent;
    outline: none;
}
.login-input .btn.gray {
    color: #999;
}
.login-input-val {
    height: 0.8rem;
    flex: 1;
    background-color: #f8f8f8;
    padding-left: 0.72rem;
    outline: none;
}
.login-input-val::placeholder {
    color: #bfbfbf;
}
.login-xy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.login-xy .img {
    width: 0.28rem;
    height: 0.28rem;
    margin-right: 0.08rem;
    margin-top: 0.06rem;
}
.login-xy .txt {
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #999;
}
.login-xy .link {
    color: #409EFF;
}
.login-btn {
    padding-top: 0.48rem;
    display: flex;
    flex-direction: row;
}
.login-btn .btn {
    background-color: #FD256C;
    color: #fff;
    border-radius: 0.4rem;
    height: 0.8rem;
    flex: 1;
}
</style>