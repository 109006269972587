<template>
    <router-view/>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
@import url("assets/css/base.css");
</style>
