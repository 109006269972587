<template>
    <div class="app-container down">
        <div class="down-swiper">
            <swiper :modules="modules" :autoplay="true">
                <swiper-slide>
                    <img class="down-img" src="../assets/images/1.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="down-img" src="../assets/images/2.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="down-img" src="../assets/images/3.png" alt="">
                </swiper-slide>
                <swiper-slide>
                    <img class="down-img" src="../assets/images/4.png" alt="">
                </swiper-slide>
            </swiper>
        </div>
        <div class="down-btn">
            <a :href="appurl" class="btn" v-if="appurl">客户端</a>
            <a href="javascript:;" @click="showTips" class="btn gray" v-else>客户端</a>
            <a :href="techurl" class="btn" v-if="techurl">技师端</a>
            <a href="javascript:;" @click="showTips" class="btn gray" v-else>技师端</a>
            <!-- <a class="btn" :href="url" v-if="url">立即下载</a>
            <a class="btn gray" href="javascript:;" @click="showTips" v-else>立即下载</a> -->
        </div>
        <div class="down-tips" v-if="isBox">
            <img src="../assets/images/tip.png" alt="" class="img">
        </div>
        <div class="down-message" v-if="isTip">{{ platform }}暂未上线，尽请期待！</div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { appUpgrade } from '../api/version.js'
import { isAndroid, isWeixin } from '../utils/util.js'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/autoplay'

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const temp = isAndroid() ? 'android' : 'ios'
        let appurl = ref('')
        let techurl = ref('')
        let isBox = ref(false)
        let platform = ref(temp)
        let isTip = ref(false)
        
        const getMemberApp = function () {
            const data = {
                name: 'APP',
                platform: temp
            }
            appUpgrade(data).then(res => {
                try {
                    appurl.value = res.version.url.package
                } catch(e) {
                    console.log(e)
                }
            })
        }
        
        const getTechApp = function () {
            const data = {
                name: 'TECH',
                platform: temp
            }
            appUpgrade(data).then(res => {
                try {
                    techurl.value = res.version.url.package
                } catch(e) {
                    console.log(e)
                }
            })
        }
        
        onMounted(() => {
            // 获取会员端版本
            getMemberApp()
            
            // 获取技师端
            getTechApp()
            
            // 是否弹窗
            if (isWeixin()) {
                isBox.value = true
            }
        })
        
        const showTips = function () {
            if (!isTip.value) {
                isTip.value = true
                setTimeout(() => {
                    isTip.value = false
                }, 3000)
            }
        }
        
        return {
            appurl,
            techurl,
            isBox,
            platform,
            isTip,
            showTips,
            modules: [Autoplay]
        }
    }
}
</script>

<style scoped>
.down-swiper {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}
.down-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.down-btn {
    position: absolute;
    width: 100%;
    height: 4rem;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-image: url('../assets/images/btn.png');
    background-size: cover;
    background-position: center top;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 0.32rem 0.48rem;
}
.down-btn .btn {
    width: 3.2rem;
    height: 0.8rem;
    border-radius: 0.08rem;
    background-color: #FD256C;
    color: #fff;
    line-height: 0.8rem;
    text-align: center;
    font-size: 0.28rem;
    outline: none;
}
.down-btn .gray {
    background-color: #999;
}
.down-tips {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    padding: 0.8rem 0 0;
}
.down-tips .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.down-message {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 0.24rem;
    border-radius: 0.08rem;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
