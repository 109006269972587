<template>
    <div class="app-container privacy">
        <div class="privacy-box" v-html="privacy"></div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { settingAgreement } from '../api/version.js'

let privacy = ref({})

const getAgreement = key => {
    settingAgreement({ key }).then(res => {
        privacy.value = res.value
    })
}

onMounted(() => {
    const router = useRouter()
    const key = router.currentRoute.value.query.key
    getAgreement(key)
})
</script>

<style scoped>
.privacy-box {
    padding: 0.48rem 0.32rem 0.8rem;
}
</style>

<style>
.privacy-box p {
    text-align: justify;
    line-height: 0.48rem;
}
</style>