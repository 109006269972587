import { createRouter, createWebHashHistory } from "vue-router"
import DownPage from '../pages/DownPage.vue'
import PrivacyPage from '../pages/PrivacyPage.vue'
import SupportPage from '../pages/SupportPage.vue'
import LoginPage from '../pages/LoginPage.vue'

const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:'/',
            component: DownPage
        },
        {
            path:'/privacy',
            component: PrivacyPage
        },
        {
            path:'/support',
            component: SupportPage
        },
        {
            path:'/login',
            component: LoginPage
        }
    ]
})

export default router
